import AppBanner from '../../components/app-banner/app-banner.vue'
import Itineraries from '../../components/itineraries/itineraries.vue'

export default {
  name: 'SellItineraries',
  title: 'Buy & sell travel itineraries globally',
  components: {
    AppBanner,
    Itineraries
  }
}
import Experiences from '../../components/experiences/experiences.vue'
import People from '../../components/people/people.vue'

//custom services
import ExperienceService from '../../services/experience';

export default {
  name: 'ExperiencesAll',
  title: 'Host and earn with e-experiences - Localites',

  components: {
    Experiences,
    People
  },

  props: [
    'onScroll'
  ],

  methods: {
    searchExperiences() {
      this.refresh = true;
      setTimeout(() => { this.refresh = false }, 1000);
    }
  },

  mounted() {
    ExperienceService
      .getEnums()
      .then(result => this.categories = result.data.categories.filter(x => x != 'Other'))
      .catch(err => console.log(err));
  },

  data() {

    return {
      categories: [],
      
      filters: {
        categories: [],
        search: '',
      },
      
      valid: false,
      refresh: false,
      validationRules: {
        basicRules: [
          v => !!v || !!this.filters.categories.length || ''
        ]
      }
    }
  }
}
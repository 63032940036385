export default {
  name: 'Banner',
  computed: {
    currentPath() {
      return this.$route.path;
    },

    isLoggedOut() {
      return this.$store.state.isLoggedOut;
    },
    
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },

    showBanner() {
      return this.$store.state.showBanner;
    },

    authStatus() {
      return this.$store.getters.authStatus;
    },

    isIncomplete() {
      return this.$store.state.userInfo.mobile;
    }
  }
}
import EventBus from '../../event-bus';
import config from '../../config'

export default {
  name: 'LoginModal',

  created() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : '1047743615431710',
        cookie: true, // This is important, it's not enabled by default
        version: "v7.0"
      });
      window.FB.AppEvents.logPageView();
    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  },

  methods: {

    showModal() {
      this.loginModal = true;
    },
    hideModal() {
      this.loginModal = false;
    },

    logInWithFacebook () {
      if (this.$store.state.authenticated) return;

      this.fbAuth = this.progress = true;
      let nthis = this;
      window.FB.login(function(response) {
        if (response.authResponse) {
          window.FB.api('/me?fields=id,name,email', function(response) {
            nthis.serverSubmission({
              name: response.name,
              email: response.email,
              fbId: response.id
            }, 'fb');
          });
        } else {
          this.showError = true;
          this.fbAuth = false;
          setTimeout(() => { this.showError = false }, 2500);
        }
      });
      return false;
    },

    withGoogle() {
      if (this.$store.state.authenticated) return;
      this.googleAuth = this.progress = true;
      this.$gAuth.signIn()
        .then(googleUser => {
          const guser = googleUser.getBasicProfile();
          const user = {
            name: guser.getName(), 
            email: guser.getEmail(), 
            gpId: guser.getId(),
            image: guser.getImageUrl()
          };
          this.serverSubmission(user, 'google');
        })
        .catch(err => {
          console.log(err);
          this.showError = true;
          this.googleAuth = false;
          setTimeout(() => { this.showError = false }, 2500);
        })
    },

    withEmail() {
      this.emailAuth = true;
    },

    submitEmail() {
      if (!this.emailRegex.test(this.user.email)) this.invalidEmail = true;
      else this.invalidEmail = false;
      if (this.user.signup && !this.$refs.form.validate()) return;
      else if (!this.user.signup && (!this.user.email || !this.user.password || !this.emailRegex.test(this.user.email))) return this.$refs.form.validate();

      this.serverSubmission(this.user, 'email');
    },

    serverSubmission(user, type) {
      this.progress = true;
      user.type = type;
      this.$store.dispatch('login', user)
        .then(() => {
          this.disableProgress(type);

          if (type == 'email' && this.user.signup) {
            this.showSuccess = 'We have sent you an email to verify. Please check your inbox (if not there, check junk/spam) and click on the link to proceed.';
            setTimeout(() => { 
              this.showSuccess = '';
              this.hideModal();
            }, 5000)
          } else {
            this.hideModal();
            const nu = this.params.nextUrl;
            if (nu && nu != this.$router.currentRoute.path) this.$router.push(nu);
          }
        })
        .catch(err => {
          if (err.response && err.response.data.message === 'already-registered') this.showError = 'This email is already registered with us. Try sign-in.'
          else if (err.response && err.response.status === 401) this.showError = 'Invalid credentials';
          else this.showError = true;

          this.disableProgress(type);
          setTimeout(() => { this.showError = false }, 2500);
        });
    },

    disableProgress(type) {
      if (type == 'fb') this.fbAuth = false;
      else if (type == 'google') this.googleAuth = false;

      this.progress = false;
    }
  },

  mounted() {
    EventBus.$on('prompt-login', function(params) {
      this.params = params;
      this.showModal();
    }.bind(this));
  },

  data() {
    return {
      showError: false,
      showSuccess: false,
      fbAuth: false,
      googleAuth: false,
      emailRegex: /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i,
      emailAuth: false,
      loginModal: false,
      invalidEmail: false,
      showPassword: false,
      valid: false,
      progress: false,
      params: {},
      user: {
        signup: true,
        name: '',
        email: '',
        password: ''
      },
      validationRules: {
        nameRules: [
          v => !!v || 'Name is required',
          v => v.length <= 50 || 'Name must be less than 30 characters',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || !this.invalidEmailString || 'E-mail must be valid',
          v => !this.invalidEmail || 'Invalid email'
        ],
        passwordRules: [
          v => !!v || 'Password is required',
          v => v.length >= 6 || 'Password length must be at least up to 6 characters',
        ]
      },
      placeholder: config.meta.placeholder
    }
  }
}
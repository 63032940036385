export default {
  name: 'HomeStories',
  data() {
    return {
      stories: [
        {
          slug: 'people-are-paying-for-e-travel',
          title: 'People are paying for e-travel',
          date: 'April 27',
          year: '2020',
          img: '31.jpg',
          content: 'Lately it feels like joy has been sucked out of our lives. The world got cancelled one by one. Major world events, national events, local events, movie releases, concerts...'
        },
        {
          slug: 'que-sera-sera',
          title: 'Que Sera Sera',
          date: 'April 10',
          year: '2020',
          img: '30.jpg',
          content: 'Sigh! The World is going through a pandemic. It took me a while to accept it, but it really is. Localites is a global community, and all these while we constantly celebrated...'
        },
        {
          slug: 'celebrating-130-countries',
          title: 'Celebrating 130 countries',
          date: 'Mar 6',
          year: '2020',
          img: '28.png',
          content: 'We are thrilled! Now people from 130 countries are part of the community and exploring and traveling in different corners of the world. So we invite you to be a part of the...'
        }
      ]
    }
  }
}

// You still need to register Vuetify itself
// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { 
  VForm, 
  VInput, 
  VTextField,
  VCheckbox,
  VAutocomplete,
  VSelect,
  VDatePicker,
  VSpacer,
  VBtn,
  VDialog,
  VListItem,
  VListItemTitle,
  VListItemContent,
  VListItemAvatar,
  VListItemSubtitle,
  VImg,
  VProgressCircular,
  VBottomSheet,
  VSheet,
  VTimePicker,
  VContent,
  VApp,
  VProgressLinear,
  VTextarea,
  VOverlay,
  VContainer,
  VDivider,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VRow,
  VCol,
  VCarousel,
  VCarouselItem,
  VChip,
  VSkeletonLoader,
  VHover,
  VAlert,
  VAvatar,
  VTabs,
  VTabsItems,
  VTabItem,
  VTab,
  VRadioGroup,
  VRadio,
  VStepper,
  VStepperStep,
  VStepperContent
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VForm,
    VInput,
    VCheckbox,
    VTextField,
    VAutocomplete,
    VSelect,
    VDatePicker,
    VSpacer,
    VBtn,
    VDialog,
    VListItem,
    VListItemTitle,
    VListItemContent,
    VListItemAvatar,
    VListItemSubtitle,
    VImg,
    VProgressCircular,
    VBottomSheet,
    VSheet,
    VTimePicker,
    VContent,
    VApp,
    VProgressLinear,
    VTextarea,
    VOverlay,
    VContainer,
    VDivider,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VRow,
    VCol,
    VCarousel,
    VCarouselItem,
    VChip,
    VSkeletonLoader,
    VHover,
    VAlert,
    VAvatar,
    VTabs,
    VTabsItems,
    VTabItem,
    VTab,
    VRadioGroup,
    VRadio,
    VStepper,
    VStepperStep,
    VStepperContent
  }
})

const opts = {}

export default new Vuetify(opts)
import OpenService from '../../services/open';
import UserService from '../../services/user';

import SkeletonLoader from '../skeleton-loader/skeleton-loader';

export default {
  name: 'People',

  props: [
    'onScroll',
    'items',
    'refresh',
    'filters'
  ],
  
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    fetchMembers() {
      this.page = 1;
      this.loading = true;
      OpenService.getPeople(this.filters || {}, this.page, this.pageSize, true)
        .then(results => {

          let people = results.data;
          people.forEach(x => { return UserService.mapUser(x) });
          this.people = people;

          this.page++;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        })
    },

    loadMore() {
      window.onscroll = () => {
        const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {

          this.infiniteScroll = true;
          OpenService.getPeople(this.filters || {}, this.page, this.pageSize, true)
            .then(results => {
              
              if (results.data.length) {

                let newPeople = results.data;
                newPeople.forEach(x => { return UserService.mapUser(x) });
                newPeople.forEach(x => this.people.push(x));

                this.page++;
              }

              this.infiniteScroll = false;
            })
            .catch(err => {
              console.log(err);
              this.infiniteScroll = false;
            });

        }
      }
    },

    generateSlug(string) {
      return OpenService.generateSlug(string);
    }

  },

  beforeMount() {
    this.fetchMembers();
  },

  mounted() {
    if (this.onScroll) this.loadMore();
  },

  watch: {
    refresh(val) {
      if (val) this.fetchMembers();
    }
  },

  data() {
    return {
      people: [],
      page: 1,
      loading: false,
      infiniteScroll: false,
      pageSize: this.items || 20,
      lazy: require("@/assets/imgs/placeholder.png")
    };
  },

  components: {
    SkeletonLoader
  }
}
import UserService from '../../services/user';

export default {
  props: [
    'location',
    'editMode',
    'required',
    'disabled',
    'placeholder',
    'isPersonlized',
    'locationApi'
  ],

  methods: {
    locationSelected() {
      this.$emit('update:location', this.selectedLocation);
    },

    querySelections(val) {
      this.lastSearched = val;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;

      return UserService
        .search(val, false, this.locationApi)
        .then(response => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.locations = response.data;
        })
        .catch(() => {
          this.outstandingRequests--;
        });
    }
  },

  watch: {
    search(newVal, oldVal) {
      if (newVal && newVal != oldVal && newVal != this.lastSearched) this.querySelections(newVal);
    }
  },

  data() {
    return {
      search: '',
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,

      lastSearched: '',
      
      locations: this.isPersonlized ? [] : [this.location],
      selectedLocation: this.location,

      locationRules: [
        v => !!v || 'Location (or city) is required'
      ],
    }
  }
}
<template>
  <b-row>
    <b-col data-aos="zoom-in" class="mt-25" cols="3" md="3" sm="6" xs="6" v-for="i in items" :key="i">
      <v-skeleton-loader
        class="mx-auto br-25"
        max-width="300"
        type="card, list-item"
      ></v-skeleton-loader>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'SkeletonLoader',
    props: [
      'items'
    ]
  }
</script>
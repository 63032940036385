import Experiences from '../../components/experiences/experiences.vue'
import Why from '../../components/why/why.vue'
import AppBanner from '../../components/app-banner/app-banner.vue'

export default {
  name: 'EExperience',
  title: 'Host and earn with e-experiences and e-tours - Localites',

  computed: {
    authStatus() { return this.$store.getters.authStatus },
  },

  components: {
    Experiences,
    Why,
    AppBanner
  }
}
import Vue from 'vue';

import GlobalService from './global';

export default {
  getEnums() {
    return Vue.axios.get(`w/h-ee/enums`, {noProgress: true});
  },
  
  getExperience(id) {
    return Vue.axios.get(`e/${id}`, {noProgress: true});
  },

  getExperiencesById(uuid) {
    return Vue.axios.get(`e/u/${uuid}`, {noProgress: true});
  },

  createExperience(experienceObject, noProgress = false) {
    return Vue.axios.post(`e/`, experienceObject, {noProgress: noProgress});
  },

  updateExperience(experinceObject, noProgress = false) {
    return Vue.axios.put(`e/${experinceObject._id}`, experinceObject, {noProgress: noProgress});
  },

  changeStatus(experienceId, status = 'deleted', noProgress = false) {
    return Vue.axios.delete(`e/${experienceId}/${status}`, {noProgress: noProgress}); 
  },

  fetchUser(expId) {
    return Vue.axios.get(`e/fu/${expId}`, {noProgress: true});
  },

  fetchReviews(expId) {
    return Vue.axios.get(`e/fr/${expId}`, {noProgress: true});
  },
  
  mapExperience(item) {
    const firstLetter = item.uname ? item.uname.charAt(0).toLowerCase() : item.title.charAt(0).toLowerCase();
    const mappedPhotos = []

    item.photos.forEach(x => {
      if (x) {
        mappedPhotos.push({
          img: GlobalService.getExpPhoto(x), 
          lazy: GlobalService.getLazy(firstLetter)
        });
      }
    });

    if (!item.photos.length || !mappedPhotos.length) {
      mappedPhotos.push({
        img: GlobalService.getExpPhoto(undefined), 
        lazy: GlobalService.getLazy(firstLetter)
      });
    }

    item.mappedPhotos = mappedPhotos;

    return item;
  }
}
import Vue from 'vue'

import config from '../../config'

export default {
  name: 'Subscription',

  computed: {
    showModal() { return this.$store.state.showSubscriptionModal }
  },

  methods: {

    getRazOptions(subscriptionId, type) {
      let name = 'Monthly Subscription';
      if (type === '12 months') name = 'Yearly Subscription';

      return {
        key: config.keys.razorpayKey,
        subscription_id: subscriptionId,
        "name": name,
        "description": "Localites's subscription to avail premium features.",
        handler: function (response){
          console.log(response);   
          /*const subscriptionObject = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            razorpay_subscription_id: response.razorpay_subscription_id            
          }*/
        },
        prefill: {
          name: this.$store.state.userInfo.name,
          email: this.$store.state.userInfo.email
        },
        theme: {
          "color": "#0cd1e8"
        }
      };
    },

    hideModal() {
      this.$store.commit('hideSubscriptionModal');
    },

    selectPlan(plan, index) {
      this.plans.forEach(x => x.selected = false);
      plan.selected = true;
      Vue.set(this.plans, index, plan);
      this.createSubscription(plan);
    },

    createSubscription(plan) {
      this.processing = true;
      const uuid = this.$store.state.uuid;
      return Vue.axios
        .post(`u/cs/${uuid}`, {planId: plan.razorpaySubId, name: plan.name}, {noProgress: true})
        .then(response => {
          this.processing = false;
          this.pay(response.data.id, plan.name);
        })
        .catch(err => {
          console.log(err);
          this.processing = false;
        })
    },

    pay(subscriptionId, type) {
      const options = this.getRazOptions(subscriptionId, type);
      new window.Razorpay(options).open();
    },

    successCallback(payment_id) {
      console.log(payment_id);
    },

    errorCallback(error) {
      console.log(error);
    }
  },

  props: [
    'plans'
  ],

  data() {
    this.plans.forEach(item => {
      if (item.name === 'Onetime registration fee') {
        item.name = 'Forever';
        item.selected = true;
      } else item.selected = false;
    });

    return {
      selected: true,
      processing: false
    }
  }
}
import Vue from 'vue';
import GlobalService from './global';

export default {
  getUserProfile(uuid, noProgress = false) {
    return Vue.axios.get(`u/${uuid}`, {noProgress: noProgress});
  },
  getUserEnums(noProgress = false) {
    return Vue.axios.get('u/enums', {noProgress: noProgress});
  },
  search(keyword, noProgress = false, locationApi = false) {
    if (locationApi) return Vue.axios.get(`p/s/?keyword=${keyword}`, {noProgress: noProgress});
    return Vue.axios.get(`w/p?keyword=${keyword}`, {noProgress: noProgress});
  },
  saveInfo(infObject, uuid) {
    return Vue.axios.put(`u/${uuid}`, infObject);
  },
  uploadImage(data) {
    return Vue.axios.post('file', data);
  },
  uploadPhotos(photos, uuid) {
    return Vue.axios.post(`u/photos/${uuid}`, photos);
  },
  removeImage(id) {
    if (id.split('.').includes('googleusercontent')) return Vue.axios.put('u/photos', {id: id}); //if it's a google photo
    return Vue.axios.delete(`u/photos/${id}`);
  },
  getPayoutPaouts(noProgress = false) {
    return Vue.axios.get(`u/bd/list`, {noProgress: noProgress});
  },
  addBankAccount(infObject, noProgress = false) {
    return Vue.axios.post(`u/bd`, infObject, {noProgress: noProgress});
  },
  modifyBankAccount(infObject, noProgress = false) {
    return Vue.axios.put(`u/bd/${infObject._id}`, infObject, {noProgress: noProgress});
  },
  removeBankAccount(id, noProgress = false) {
    return Vue.axios.delete(`u/bd/${id}`, {noProgress: noProgress});
  },
  submitAVRequest(infObject, uuid, noProgress = false) {
    return Vue.axios.post(`u/vr/${uuid}`, infObject, {noProgress: noProgress})
  },

  fetchTrips(uuid) {
    return Vue.axios.get(`u/trips/${uuid}`, {noProgress: true})
  },

  fetchFriends(uuid) {
    return Vue.axios.get(`u/frnds/${uuid}`, {noProgress: true});
  },

  fetchReferences(uuid) {
    return Vue.axios.get(`u/refs/${uuid}`, {noProgress: true});
  },

  getReportingEnums() {
    return Vue.axios.get(`u/r/enums`, {noProgress: true});
  },

  reportUser(uuid, infObject) {
    return Vue.axios.post(`u/r/${uuid}`, infObject, {noProgress: true})
  },

  addFriend(uuid) {
    return Vue.axios.post(`u/fr/${uuid}`, {}, {noProgress: true})
  },

  mapUser(item) {
    const firstLetter = item.name.charAt(0).toLowerCase();
    const mappedPhotos = [];

    if (item.photos.length) {
      item.photos.forEach(x => {
        mappedPhotos.push({
          img: GlobalService.getUserPhoto(x), 
          lazy: GlobalService.getLazy(firstLetter)
        });
      });
    } else {
      mappedPhotos.push({
        img: GlobalService.getUserPhoto('', firstLetter), 
        lazy: GlobalService.getLazy(firstLetter) 
      });
    }

    item.mappedPhotos = mappedPhotos;
    return item;
  }
}

import axios from 'axios'
import NProgress from 'nprogress';
import config from './config'

// create a new axios instance
const instance = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? config.apiDev : config.apiProd,
  //baseURL: config.apiProd
})

instance.defaults.headers.common['X-Request-User-Agent'] = 'application/web-app'

// before a request is made start the nprogress
instance.interceptors.request.use(config => {
  if (!config.noProgress) NProgress.start()
  return config
})

// before a response is returned stop nprogress
instance.interceptors.response.use(response => {
  NProgress.done();
  return response
})

export default instance
import OpenService from '../../services/open';
import ExperienceService from '../../services/experience';

import SkeletonLoader from '../skeleton-loader/skeleton-loader';

export default {
  name: 'Experiences',
  
  methods: {

    fetchExperiences() {
      this.page = 1;
      this.loading = true;
      return OpenService
        .getExperiences(this.filters || {}, this.page, this.pageSize, true)
        .then(results => {
          
          let experiences = results.data;
          experiences.forEach(x => { return ExperienceService.mapExperience(x) });
          this.experiences = experiences;

          this.loading = false;
          this.page++;
        })
        .catch(err => {
          console.log(err);
        })
    },

    loadMore() {
      window.onscroll = () => {
        const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {

          this.infiniteScroll = true;
          OpenService.getExperiences(this.filters || {}, this.page, this.pageSize, true)
            .then(results => {
              if (results.data.length) {
                let newExperiences = results.data;
                newExperiences.forEach(x => { return ExperienceService.mapExperience(x) });
                newExperiences.forEach(x => this.experiences.push(x));
                this.page++;
              }
              this.infiniteScroll = false;
            })
            .catch(err => {
              console.log(err);
              this.infiniteScroll = false;
            });
            
        }
      }
    },

    generateSlug(string) {
      return OpenService.generateSlug(string);
    }
  },

  beforeMount() {
    this.fetchExperiences();
  },

  props: [
    'onScroll',
    'refresh',
    'filters',
    'items'
  ],

  watch: {
    refresh(val) {
      if (val) this.fetchExperiences();
    }
  },

  mounted() {
    if (this.onScroll) this.loadMore();
  },

  components: {
    SkeletonLoader
  },

  data() {
    return {
      experiences: [],
      page: 1,
      loading: false,
      pageSize: this.items || 8,
      infiniteScroll: false,
      lazy: require("@/assets/imgs/placeholder.png"),
    };
  }
}
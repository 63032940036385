import People from '../../components/people/people.vue'

import CstmLocationPicker from '../../components/cstm-location-picker/cstm-location-picker.vue';

export default {
  name: 'Members',
  title: 'Meet and connect with people - Localites',

  components: {
    People,
    CstmLocationPicker
  },

  methods: {
    searchMembers() {
      this.refresh = true;
      setTimeout(() => { this.refresh = false }, 1000);
    }
  },

  watch: {
    location(val) {
      if (val) {
        this.location = val;
        this.filters = {
          location: this.location
        }
      } else {
        this.location = {
          placeId: '',
          description: ''
        }
        this.filters = {};
      }
    }
  },

  data() {
    return {
      filters: {},

      location: {
        placeId: '',
        description: ''
      },
      
      valid: false,
      refresh: false,
      validationRules: {
        basicRules: [
          v => !!v || ''
        ]
      }
    }
  }
}
import Vue from 'vue'
import Vuex from 'vuex'
import GlobalService from './services/global';
import router from './router'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,

  state: {
    authenticated: false,
    token: localStorage.getItem('token') || '',
    userInfo: {},
    uuid: '',

    showBanner: true,
    isLoggedOut: false,
    isLoggedIn: false,

    showSubscriptionModal: false
  },

  mutations: {
    authSuccess(state, infoObject) {
      const token = infoObject.token;
      delete infoObject.token;

      state.authenticated = true;
      state.token = token;
      state.userInfo = infoObject;
      state.uuid = infoObject._id;

      //store token in the localStorage and headers
      localStorage.setItem('token', token);
      Vue.axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    },
    authError(state) {
      state.authenticated = false;
      state.token = state.uuid = '';
      state.userInfo = {};
    },
    verifySuccess(state, infoObject) {
      state.authenticated = true;
      state.userInfo = infoObject;
      state.uuid = infoObject._id;
    },

    modifyPhotos(state, photos) {
      state.userInfo.photos = photos;
    },

    modifyBasicInfo(state, info) {
      if (info.name) state.userInfo.name = info.name;
      if (info.email) state.userInfo.email = info.email
      if (info.mobile) state.userInfo.mobile = info.mobile;
    },

    logout(state) {
      state.token = '';
      state.authenticated = false;
      localStorage.removeItem('token');
      state.isLoggedOut = true;
      if (router.history.current.path != '/') router.push('/');
      setTimeout(() => { state.isLoggedOut = false }, 2500);
    },

    unset(state) {
      state.isLoggedOut = state.isLoggedIn = false;
    },

    loggedIn(state) {
      state.isLoggedIn = true;
      setTimeout(() => { state.isLoggedIn = false }, 2500);
    },

    hideSubscriptionModal(state) {
      state.showSubscriptionModal = false;
    },

    showSubscriptionModal(state) {
      state.showSubscriptionModal = true;
    }
  },

  actions: {
    login({commit}, uobj) {
      uobj.device = {
        token: '', //later for webtoken
        udid: '', //later
        model: '', //later
        platform: 'web'
      }

      return new Promise((resolve, reject) => {
        Vue.axios.post('a', uobj)
          .then(resp => {
            if (uobj.type !== 'email' || (uobj.type === 'email' && !uobj.signup)) {
              commit('authSuccess', resp.data);
              commit('loggedIn');
            }

            resolve(resp)
          })
          .catch(err => {
            localStorage.removeItem('token');
            commit('authError');
            reject(err)
          })
      })
    },
    
    verify({commit}) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('a/v')
          .then(resp => {
            commit('verifySuccess', resp.data);
            resolve(resp);
          })
          .catch(err => {
            localStorage.removeItem('token');
            commit('authError');
            reject(err)
          })
      })
    }
  },

  getters: {
    isAuthenticated: state => !!state.token,

    authStatus: state => state.authenticated,

    userInfo: state => state.userInfo,
    
    isTokenAvailable: () => localStorage.getItem('token'),
    
    getUserPhoto: state => {
      let data = state.userInfo.photos[0];
      return GlobalService.getUserPhoto(data);
    },

    isMobile: () =>  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  }
});

export default store;
export default {
  baseAPI: 'https://api-localites.herokuapp.com/',

  apiDev: 'https://localguide-srv.herokuapp.com/api/',
  apiProd: 'https://api-localites.herokuapp.com/api/',
  
  experiencesS3PhotoURL: 'https://localites-user-experiences.s3.ap-south-1.amazonaws.com/',
  itinerariesS3PhotoURL: 'https://localites-user-itineraries.s3.ap-south-1.amazonaws.com/',
  usersS3PhotoURL: 'https://localites-user-photos.s3-ap-northeast-1.amazonaws.com/',

  apiCountries: 'https://restcountries.eu/rest/v2/all?fields=name;callingCodes;currencies;flag;alpha2Code',

  fImageURL: 'https://fakeimg.pl/480x480/0cd1e8,128/000,255?font=noto&text=',
  fDarkLURL: 'https://fakeimg.pl/480x480/000000/?font=noto&text=',

  googlePlay: 'https://play.google.com/store/apps/details?id=com.localites',
  appStore: 'https://apps.apple.com/app/id1477303350',

  placeholderImg: 'https://localites.co/placeholder.png',

  meta: {
    placeholder: 'Type here...',
    bsaveMsg: 'Saving...',
    bsMsg: 'Modified successfully.',
    bcMsg: 'No new change detected.',

    beMsg: 'Something went wrong. Please try again.',
    befMsg: 'Something is yet to fill correctly. Please review the form.',
    bmneMsg: 'Mobile number is already registered.',
    beeMsg: 'Email is already registered.'
  },

  keys: {
    razorpayKey: 'rzp_test_NNjmkFKjZ9glv5'
  }
}
import CurrencyModal from '../currency-modal/currency-modal.vue';
import EventBus from '../../event-bus';

export default {
  name: 'Header',
  computed: {
    currentPath() { return this.$route.path; },
    cpName() { return this.$route.name; },
    authStatus() { return this.$store.getters.authStatus },
    userInfo() { return this.$store.getters.userInfo },
    getUserPhoto() { return this.$store.getters.getUserPhoto },
    firstName() { return this.userInfo.name.split(' ').length === 1 ? this.userInfo.name : this.userInfo.name.split(' ').slice(0, -1).join(' '); },
    uuid() { return this.$store.state.uuid },

    isDark() { 
      return this.$route.name === 'experience-detail' || this.$route.name === 'member-detail' || this.$route.name === 'itinerary-detail' || this.$route.name === 'careers';
    }
  },
  components: {
    CurrencyModal
  },
  methods: {
    showCurrencyModal() {
      this.$refs.currencyModal.$refs['currency-modal'].show();
    },
    login() {
      EventBus.$emit('prompt-login', {});
    },
    logout() {
      this.$store.commit('logout');
    }
  }
}
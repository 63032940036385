import Experiences from '../../components/experiences/experiences.vue'
import Trusted from '../../components/trusted/trusted.vue'
import People from '../../components/people/people.vue'
import Introducing from '../../components/introducing/introducing.vue'
import Testimonials from '../../components/testimonials/testimonials.vue'
import HomeStories from '../../components/home-stories/home-stories.vue'
import CstmLocationPicker from '../../components/cstm-location-picker/cstm-location-picker.vue';
import Itineraries from '../../components/itineraries/itineraries.vue'

import EventBus from '../../event-bus';
import GlobalService from '../../services/global';

export default {
  name: 'Home',
  title: 'People, Experiences, Itineraries & Tours - Localites',
  
  components: {
    Experiences,
    Trusted,
    People,
    Introducing,
    Testimonials,
    HomeStories,
    CstmLocationPicker,
    Itineraries
  },

  computed: {
    authStatus() { return this.$store.getters.authStatus; },
    isMobile() { return this.$store.getters.isMobile; }
  },

  watch: {
    playVideo(val) {
      /*stopping on close*/
      if (!val && this.$refs.playVideoPlayer) {
        const iframe = this.$refs.playVideoPlayer;
        let url = iframe.src;
        url = url.replace("&autoplay=1", "");
        this.$refs.playVideoPlayer.src = '';
        this.$refs.playVideoPlayer.src = url;
      }
    }
  },

  methods: {
    login() {
      EventBus.$emit('prompt-login', {});
    },

    getDarkLazy() {
      return GlobalService.getDarkLazy();
    }
  },

  data() {
    return {
      playVideo: false,
      img1: require("@/assets/imgs/ee/1.jpg"),
      img2: require("@/assets/imgs/people/12.jpg"),
      img3: require("@/assets/imgs/it/4.jpg"),
      dlazy: require("@/assets/imgs/dark-lazy.png")
    }
  }
}
import Vue from 'vue'

import Banner from './components/banner/banner.vue'
import Header from './components/header/header.vue'
import Footer from './components/footer/footer.vue'

Vue.component('LoginModal', require('./components/login-modal/login-modal.vue').default);
Vue.component('Subscription', require('./components/subscription/subscription.vue').default);

export default {
  name: 'App',
  
  computed: {
    noHF() { return  this.$route.name === 'new-experience' || 
        this.$route.name === 'new-experience-info' || 
        this.$route.name === 'new-itinerary' || 
        this.$route.name === 'new-itinerary-info' || 
        this.$route.name === 'verify'; }
  },

  components: {
    Banner,
    Header,
    Footer
  }
}
import CstmLocationPicker from '../../components/cstm-location-picker/cstm-location-picker.vue';
import ItinerariesList from '../../components/itineraries/itineraries.vue';

export default {
  name: 'Itineraries',
  title: 'Explore cities with itineraries curated by explorers - Localites',

  components: {
    CstmLocationPicker,
    ItinerariesList
  },

  methods: {
    searchItineraries() {
      this.refresh = true;
      setTimeout(() => { this.refresh = false }, 1000);
    }
  },

  watch: {
    location(val) {
      if (val) {
        this.location = val;
        this.filters = {
          location: this.location
        }
      } else {
        this.location = {
          placeId: '',
          description: ''
        }
        this.filters = {};
      }
    }
  },

  data() {
    return {
      valid: false,
      refresh: false,
      filters: {},
      location: {
        placeId: '',
        description: ''
      }
    }
  }
}
import AppBanner from '../../components/app-banner/app-banner.vue';
import Testimonials from '../../components/testimonials/testimonials.vue';
import Why from '../../components/why/why.vue';
import People from '../../components/people/people.vue';

export default {
  name: 'ShowAround',
  title: 'Show travelers around and earn - Localites',
  components: {
    AppBanner,
    Testimonials,
    Why,
    People
  }
}
import Vue from 'vue';

export default {
  name: 'CurrencyModal',
  methods: {
    hideModal() {
      this.$refs['currency-modal'].hide();
    }
  },
  mounted() {
    return Vue.axios.get('w/countries')
      .catch((error) => {
        throw new Error(error);
      })
      .then(results => {
        let currencies = [];
        localStorage.setItem('countries', JSON.stringify(results.data));
        results.data.forEach(item => {
          if (!currencies.find(x => item.currencies[0].code == x.code)) {
            currencies.push({
              country: item.name,
              code: item.currencies[0].code,
              name: item.currencies[0].name,
              symbol: item.currencies[0].symbol
            });
          }
        });
        this.currencies = currencies;
      });
  },
  data() {
    return {
      currencies: []
    }
  }
}
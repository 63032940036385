import Vue from 'vue'
import App from './app.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'
import VueAxios from 'vue-axios'
import AOS from 'aos';
import titleMixin from './mixins/titleMixin';
import http from './http'
import store from './store'
import GAuth from 'vue-google-oauth2'
import vuetify from './plugins/vuetify';
import NProgress from 'nprogress';

//css import
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/custom.scss'
import 'aos/dist/aos.css';

Vue.config.productionTip = false
const gauthOption = {
  clientId: '91622237099-jl924cghcapr3n2o93a6f3uqb0e0cr5s.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.mixin(titleMixin);
Vue.use(VueAxios, http);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(GAuth, gauthOption);

Vue.filter('date', date => moment(date).format('MMMM DD, YYYY'));
vuetify.framework.theme.themes.light.primary = '#0cd1e8';

NProgress.start();

new Vue({
  created () {
    AOS.init();
  },
  router,
  render: h => h(App),
  store,
  vuetify
}).$mount('#app')

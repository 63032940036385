import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress';

import Home from '../views/home/home.vue'
import EExperiences from '../views/e-experiences/e-experiences.vue'
import Experiences from '../views/experiences/experiences.vue'
import Itineraries from '../views/itineraries/itineraries.vue'
import ShowAround from '../views/show-around/show-around.vue'
import SellItineraries from '../views/sell-itineraries/sell-itineraries.vue'
import Members from '../views/members/members.vue'
import Verify from '../views/verify/verify'


import EventBus from '../event-bus'
import store from '../store'

NProgress.configure({ showSpinner: false });

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/verify/:token',
    name: 'verify',
    component: Verify,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/e-experiences',
    name: 'e-experiences',
    component: EExperiences,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/experiences',
    name: 'experiences',
    component: Experiences,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/experiences/:slug/:id',
    name: 'experience-detail',
    component: () => import('../views/experience/experience.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/itineraries',
    name: 'itineraries',
    component: Itineraries,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/itineraries/:slug/:id',
    name: 'itinerary-detail',
    component: () => import('../views/itinerary/itinerary.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sell-itineraries',
    name: 'sell-itineraries',
    component: SellItineraries,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/show-around',
    name: 'show-around',
    component: ShowAround,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/members',
    name: 'members',
    component: Members,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/members/:slug/:id',
    name: 'member-detail',
    component: () => import('../views/member/member.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-experience',
    name: 'new-experience',
    component: () => import('../views/new-experience/new-experience.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/new-experience/:slug/:id?',
    name: 'new-experience-info',
    component: () => import('../views/new-experience-info/new-experience-info.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manage-experiences',
    name: 'manage-experiences',
    component: () => import('../views/manage-experiences/manage-experiences.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-itinerary',
    name: 'new-itinerary',
    component: () => import('../views/new-itinerary/new-itinerary.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/new-itinerary/:slug/:id?',
    name: 'new-itinerary-info',
    component: () => import('../views/new-itinerary-info/new-itinerary-info.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manage-itineraries',
    name: 'manage-itineraries',
    component: () => import('../views/manage-itineraries/manage-itineraries.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import('../views/inbox/inbox.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/travelers',
    name: 'travelers',
    component: () => import('../views/travelers/travelers.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/localites-ambassador-program',
    name: 'localites-ambassador-program',
    component: () => import('../views/localites-ambassador-program/localites-ambassador-program.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/ask-anything',
    name: 'ask-anything',
    component: () => import('../views/ask-anything/ask-anything.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/safety',
    name: 'safety',
    component: () => import('../views/safety/safety.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/how-to-use',
    name: 'how-to-use',
    component: () => import('../views/how-to-use/how-to-use.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/community-guidelines',
    name: 'community-guidelines',
    component: () => import('../views/community-guidelines/community-guidelines.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/faq/faq.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/about.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import('../views/careers/careers.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/stories',
    name: 'stories',
    component: () => import('../views/stories/stories.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/privacy-policy/privacy-policy.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import('../views/terms-of-use/terms-of-use.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/stories/:slug',
    name: 'story',
    component: () => import('../components/story/story.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/android',
    beforeEnter() {
      window.location = "https://play.google.com/store/apps/details?id=com.localites"
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/ios',
    beforeEnter() {
      window.location = "https://apps.apple.com/app/id1477303350"
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/jobs',
    beforeEnter() {
      window.location = "https://angel.co/company/localites-co/jobs"
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/account/account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/:slug',
    name: 'account-slug',
    component: () => import('../views/account-slug/account-slug.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  scrollBehavior(to, from) {
    if (from.name == 'experience-detail') return;
    if (from.name == 'member-detail') return;
    else return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
  mode: 'history'
});

//auth guard
router.beforeEach((to, from, next) => {
  //for real-time if user deletes token from storage
  if (!localStorage.getItem('token')) store.commit('authError'); 
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {

      EventBus.$emit('prompt-login', { nextUrl: to.fullPath });
      next(false);

    } else {

      if (!store.state.userInfo.name) {
        const token = store.state.token;
        Vue.axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        store.dispatch('verify')
          .then(() => next())
          .catch(() => EventBus.$emit('prompt-login', { nextUrl: to.fullPath }));
      } else {
        next();
      }

    }
  } else {

    //if user forceRefresh the page
    if (store.getters.isAuthenticated && !store.state.userInfo.name) {
      const token = store.state.token;
      Vue.axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
      store.dispatch('verify')
        .then(() => next())
        .catch(() => EventBus.$emit('prompt-login', { nextUrl: to.fullPath }));
    } else {
      next();
    }
  
  }
})

/*as we've added loading for http now*/
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router

import Vue from 'vue';

export default {
  getExperiences(filter = {}, page = 1, limit = 8, noProgress = false) {
    return Vue.axios.post(`w/h-ee/${page}/${limit}`, filter, {noProgress: noProgress});
  },

  getPeople(filter = {}, page = 1, limit = 8, noProgress = false) {
    return Vue.axios.post(`w/h-v/${page}/${limit}`, filter, {noProgress: noProgress});
  },

  getItineraries(filter = {}, page = 1, limit = 8, noProgress = false) {
    return Vue.axios.post(`w/h-i/${page}/${limit}`, filter, {noProgress: noProgress});
  },

  generateSlug(string) {
    return string.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
  }
}

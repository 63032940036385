import Vue from 'vue';
import config from '../config';

import GlobalService from './global';

export default {
  getItinerary(id) {
    return Vue.axios.get(`i/${id}`, {noProgress: true});
  },

  getItinerariesById(uuid) {
    return Vue.axios.get(`i/u/${uuid}`, {noProgress: true});
  },

  createItinerary(itineraryObject, noProgress = false) {
    return Vue.axios.post(`i/`, itineraryObject, {noProgress: noProgress});
  },

  updateItinerary(itineraryObject, noProgress = false) {
    return Vue.axios.put(`i/${itineraryObject._id}`, itineraryObject, {noProgress: noProgress});
  },

  changeStatus(itId, status = 'deleted', noProgress = false) {
    return Vue.axios.delete(`i/${itId}/${status}`, {noProgress: noProgress}); 
  },

  fetchUser(itId) {
    return Vue.axios.get(`i/fu/${itId}`, {noProgress: true});
  },

  fetchReviews(itId) {
    return Vue.axios.get(`i/fr/${itId}`, {noProgress: true});
  },
  
  mapItinerary(item) {
    const firstLetter = item.uname ? item.uname.charAt(0).toLowerCase() : item.title.charAt(0).toLowerCase();
    const mappedPhotos = []

    item.photos.forEach(x => {
      if (x) {
        mappedPhotos.push({
          img: GlobalService.getItPhoto(x), 
          lazy: GlobalService.getLazy(firstLetter)
        });
      }
    });

    if (!item.photos.length || !mappedPhotos.length) {
      mappedPhotos.push({
        img: GlobalService.getItPhoto(undefined), 
        lazy: GlobalService.getLazy(firstLetter)
      });
    }

    item.mappedPhotos = mappedPhotos;
    return item;
  }
}
<template>
  <div class="verify-accont">
    <b-container>
      <b-row>
        <b-col class="pl-10">
          <router-link to="/">
            <img src="@/assets/imgs/brand-logo.png" class="d-inline-block align-top" height="45" />
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center">
          <p v-if="processing" class="text-warning">
            Please wait while you verify your request...
          </p>
          <p v-if="error" class="text-danger">
            This is an invalid or expired link. Please wait while you redirect you...
          </p>
          <p v-if="success" class="text-success">
            Your email has been verified successfully. Please wait while you redirect you... 
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import Vue from 'vue';

  export default {
    mounted() {
      this.processing = true;
      return Vue.axios.post(`a/account/verify`, {token: this.token})
        .then(() => { 
          this.processing = false;
          this.success = true;
          setTimeout(() => {
            this.$router.push({name: 'home', replace: true});
            this.success = false;
          }, 2500)
        })
        .catch(() => {
          this.error = true 
          this.processing = false;
          setTimeout(() => {
            this.$router.push({name: 'home', replace: true});
            this.error = false;
          }, 2500)
        });
    },

    data() {
      return {
        success: false,
        error: false,
        token: this.$route.params.token,
        processing: false
      }
    }
  }
</script>